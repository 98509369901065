* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* Light Theme Colors */
body[theme='false'] {
  /* Color variables */
  --color-1: rgb(196, 220, 241);
  --color-2: white;
  --color-3: rgb(139, 94, 94);
  --color-4: rgb(80, 82, 110);
  --color-5: #fff;
  --links-a: rgb(8, 23, 243);
  --links-h: rgb(139, 94, 94);
}

/* Dark Theme colors */
body[theme='true'] {
  --color-1: #1e1f26;
  --color-2: #292c33;
  --color-3: rgb(39, 40, 42);
  --color-4: rgb(186, 186, 202);
  --color-5: #fff;
  --links-a: rgb(250, 245, 245);
  --links-h: rgb(196, 220, 241);
}

body {
  /* position: relative; */
  color: var(--color-4);
  background-color: var(--color-1);
  min-height: 100vh;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

h4 {
  margin-top: 3px;
  margin-bottom: 6px;
}

section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mainContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 600px;
  max-width: 1200px;
  top: 65px;
}

.greeting {
  padding: 20px;
  margin: 20px;
  background-color: var(--color-2);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 10px;
  width: fit-content;
  background-color: var(--color-2);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.app {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  margin: 20px;
  background-color: var(--color-2);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.waveStats {
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  width: 100%;
}

.waveStat {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3498db;
  height: 60px;
  min-width: 50px;
  border-radius: 20px;
  padding: 15px;
  color: var(--color-5);
  box-shadow: rgba(0, 0, 0, 0.24) 1px 5px 20px;
}

a:visited {
  color: var(--links-h);
}

a:hover {
  color: var(--links-h);
}

a:link {
  color: var(--links-a);
  margin-top: 3px;
  margin-bottom: 6px;
}

.theme-switcher {
  position: absolute;
  right: 30px;
  top: 10px;
}

.switcherInput {
  width: 0px;
  height: 0px;
  visibility: hidden;
}

.switcherInput:checked + label {
  background-color: rgb(25,26, 37);
  border: 2px solid whitesmoke;
}

.switcherInput:checked + label::after {
  background-color: aliceblue;
  left: calc(100% - 25px);
}

.switcherLabel {
  display: block;
  /* Make the text dissappear */
  text-indent: -9999px;
  height: 30px;
  width: 60px;
  background-color: aliceblue;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: .5s ease background-color;
}

.switcherLabel::after {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: rgb(30,16, 233);
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: .5s ease;
}

.btnConnectWallet {
  background: #3498db;
  border-radius: 28px;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-shadow: 1px 1px 3px #666666;
  color: #ffffff;
  font-size: 20px;
  width: 50%;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
}

.btnConnectWallet:hover {
  cursor: pointer;
  background: #3cb0fdd7;
  text-decoration: none;
}

.waveForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.message {
  height: 30px;
  margin-bottom: 15px;
  border-radius: 9px;
  min-width: 400px;
  text-align: center;
}

.waveHistory {
  width: auto;
}

.waver {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
}

.waverData {
  margin-left: 5px;
  margin-right: 5px;
}

.waveMessage {
  margin-left: 5px;
  margin-right: 5px;
}

.miningIndicator {
  color: red;
}